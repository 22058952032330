<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Tender Page">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Category">
                <b-form-input
                  v-model="page.category"
                  placeholder="Category"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Sub Tender Section"
          ><b-form
            class="repeater-form"
            @submit.prevent="repeatTenderSection(null)"
          >
            <b-row
              v-for="(tender, index) in page.tenderSection"
              :key="index"
              ref="tenderItemRow"
            >
              <b-col cols="10">
                <b-form-group label="Tender Type">
                  <b-form-input
                    v-model="page.tenderSection[index].tenderType"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTenderSection(null, index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="10">
                <b-form-group label="Description">
                  <b-row>
                    <b-col cols="12">
                      <quill-editor
                        v-model="page.tenderSection[index].description"
                        :options="snowOption"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col>
                <b-card style="background: #f5f5f5" title="Tender Items">
                  <b-col cols="12">
                    <b-form
                      class="repeater-form"
                      @submit.prevent="repeatTender(null, index)"
                    >
                      <b-row
                        v-for="(tender, index1) in page.tenderSection[index]
                          .items"
                        :key="index1"
                        ref="tenderItemRow"
                      >
                        <b-col cols="5">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.tenderSection[index].items[index1].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                          <b-form-group label="PDF Link">
                            <b-form-input
                              v-model="
                                page.tenderSection[index].items[index1].pdfLink
                              "
                              placeholder="PDF Link"
                            />
                          </b-form-group>
                          <b-form-checkbox
                            checked="true"
                            v-model="
                              page.tenderSection[index].items[index1].hasNoDate
                            "
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >
                            Disable Date
                          </b-form-checkbox>
                        </b-col>
                        <b-col
                          v-if="
                            !page.tenderSection[index].items[index1].hasNoDate
                          "
                          cols="5"
                        >
                          <b-form-group label="Last Date">
                            <b-calendar
                              v-model="
                                page.tenderSection[index].items[index1].lastDate
                              "
                              block
                              locale="en-US"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col lg="2" md="3" class="mb-50">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeTenderItem(null, index, index1)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Remove</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <b-form
                            class="repeater-form"
                            @submit.prevent="
                              repeatChildTender(null, index, index1)
                            "
                          >
                            <b-row
                              v-for="(tender, index2) in page.tenderSection[
                                index
                              ].items[index1].childItems"
                              :key="index2"
                              ref="childTenderItemRow"
                            >
                              <b-col cols="12">
                                <b-card
                                  style="background: white"
                                  :title="'Tender series ' + (index2 + 1)"
                                >
                                  <b-row>
                                    <b-col cols="5">
                                      <b-form-group label="Title">
                                        <b-form-input
                                          v-model="
                                            page.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].title
                                          "
                                          placeholder="Section Title"
                                        />
                                      </b-form-group>
                                      <b-form-group label="PDF Link">
                                        <b-form-input
                                          v-model="
                                            page.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].pdfLink
                                          "
                                          placeholder="PDF Link"
                                        />
                                      </b-form-group>
                                      <b-form-checkbox
                                        checked="true"
                                        v-model="
                                          page.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        name="check-button"
                                        class="mb-1 mt-1"
                                        switch
                                        inline
                                      >
                                        Disable Date
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col cols="5">
                                      <b-form-group
                                        v-if="
                                          !page.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        label="Last Date"
                                      >
                                        <b-calendar
                                          v-model="
                                            page.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].lastDate
                                          "
                                          block
                                          locale="en-US"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col lg="2" md="3" class="mb-50">
                                      <b-button
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        variant="outline-danger"
                                        class="mt-0 mt-md-2"
                                        @click="
                                          removeTenderChildItem(
                                            null,
                                            index,
                                            index1,
                                            index2
                                          )
                                        "
                                      >
                                        <feather-icon
                                          icon="XIcon"
                                          class="mr-25"
                                        />
                                        <span>Remove</span>
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="mt-1"
                                ><b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  @click="
                                    repeatChildTender(null, index, index1)
                                  "
                                >
                                  <feather-icon icon="PlusIcon" class="mr-25" />
                                  <span>{{
                                    page.tenderSection[index].items[index1]
                                      .childItems.length === 0
                                      ? "Add Series Tender Items"
                                      : "Add New Series Item"
                                  }}</span>
                                </b-button></b-col
                              >
                            </b-row>
                          </b-form>
                        </b-col>
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="mt-1"
                          ><b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="repeatTender(null, index)"
                          >
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>{{
                              page.tenderSection[index].items.length === 0
                                ? "Add Tender Item"
                                : "Add New Tender Item"
                            }}</span>
                          </b-button></b-col
                        >
                      </b-row>
                    </b-form>
                  </b-col>
                </b-card>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatTenderSection(null)"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.tenderSection.length === 0
                      ? "Add Tender Section"
                      : "Add New Tender Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!--Hindi Translation-->
       <b-tab active title="Hindi">
        <b-card title="Tender Page">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Category">
                <b-form-input
                  v-model="page.translation.hi.category"
                  placeholder="Category"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Sub Tender Section"
          ><b-form
            class="repeater-form"
            @submit.prevent="repeatTenderSection('hi')"
          >
            <b-row
              v-for="(tender, index) in page.translation.hi.tenderSection"
              :key="index"
              ref="tenderItemRow"
            >
              <b-col cols="10">
                <b-form-group label="Tender Type">
                  <b-form-input
                    v-model="page.translation.hi.tenderSection[index].tenderType"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTenderSection('hi', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="10">
                <b-form-group label="Description">
                  <b-row>
                    <b-col cols="12">
                      <quill-editor
                        v-model="page.translation.hi.tenderSection[index].description"
                        :options="snowOption"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col>
                <b-card style="background: #f5f5f5" title="Tender Items">
                  <b-col cols="12">
                    <b-form
                      class="repeater-form"
                      @submit.prevent="repeatTender('hi', index)"
                    >
                      <b-row
                        v-for="(tender, index1) in page.translation.hi.tenderSection[index]
                          .items"
                        :key="index1"
                        ref="tenderItemRow"
                      >
                        <b-col cols="5">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.translation.hi.tenderSection[index].items[index1].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                          <b-form-group label="PDF Link">
                            <b-form-input
                              v-model="
                                page.translation.hi.tenderSection[index].items[index1].pdfLink
                              "
                              placeholder="PDF Link"
                            />
                          </b-form-group>
                          <b-form-checkbox
                            checked="true"
                            v-model="
                              page.translation.hi.tenderSection[index].items[index1].hasNoDate
                            "
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >
                            Disable Date
                          </b-form-checkbox>
                        </b-col>
                        <b-col
                          v-if="
                            !page.translation.hi.tenderSection[index].items[index1].hasNoDate
                          "
                          cols="5"
                        >
                          <b-form-group label="Last Date">
                            <b-calendar
                              v-model="
                                page.translation.hi.tenderSection[index].items[index1].lastDate
                              "
                              block
                              locale="en-US"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col lg="2" md="3" class="mb-50">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeTenderItem('hi', index, index1)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Remove</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <b-form
                            class="repeater-form"
                            @submit.prevent="
                              repeatChildTender('hi', index, index1)
                            "
                          >
                            <b-row
                              v-for="(tender, index2) in page.translation.hi.tenderSection[
                                index
                              ].items[index1].childItems"
                              :key="index2"
                              ref="childTenderItemRow"
                            >
                              <b-col cols="12">
                                <b-card
                                  style="background: white"
                                  :title="'Tender series ' + (index2 + 1)"
                                >
                                  <b-row>
                                    <b-col cols="5">
                                      <b-form-group label="Title">
                                        <b-form-input
                                          v-model="
                                            page.translation.hi.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].title
                                          "
                                          placeholder="Section Title"
                                        />
                                      </b-form-group>
                                      <b-form-group label="PDF Link">
                                        <b-form-input
                                          v-model="
                                            page.translation.hi.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].pdfLink
                                          "
                                          placeholder="PDF Link"
                                        />
                                      </b-form-group>
                                      <b-form-checkbox
                                        checked="true"
                                        v-model="
                                          page.translation.hi.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        name="check-button"
                                        class="mb-1 mt-1"
                                        switch
                                        inline
                                      >
                                        Disable Date
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col cols="5">
                                      <b-form-group
                                        v-if="
                                          !page.translation.hi.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        label="Last Date"
                                      >
                                        <b-calendar
                                          v-model="
                                            page.translation.hi.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].lastDate
                                          "
                                          block
                                          locale="en-US"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col lg="2" md="3" class="mb-50">
                                      <b-button
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        variant="outline-danger"
                                        class="mt-0 mt-md-2"
                                        @click="
                                          removeTenderChildItem(
                                            'hi',
                                            index,
                                            index1,
                                            index2
                                          )
                                        "
                                      >
                                        <feather-icon
                                          icon="XIcon"
                                          class="mr-25"
                                        />
                                        <span>Remove</span>
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="mt-1"
                                ><b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  @click="
                                    repeatChildTender('hi', index, index1)
                                  "
                                >
                                  <feather-icon icon="PlusIcon" class="mr-25" />
                                  <span>{{
                                    page.translation.hi.tenderSection[index].items[index1]
                                      .childItems.length === 0
                                      ? "Add Series Tender Items"
                                      : "Add New Series Item"
                                  }}</span>
                                </b-button></b-col
                              >
                            </b-row>
                          </b-form>
                        </b-col>
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="mt-1"
                          ><b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="repeatTender('hi', index)"
                          >
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>{{
                              page.translation.hi.tenderSection[index].items.length === 0
                                ? "Add Tender Item"
                                : "Add New Tender Item"
                            }}</span>
                          </b-button></b-col
                        >
                      </b-row>
                    </b-form>
                  </b-col>
                </b-card>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatTenderSection('hi')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.hi.tenderSection.length === 0
                      ? "Add Tender Section"
                      : "Add New Tender Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
      <!-- Marathi Translation -->
      <b-tab active title="Marathi">
        <b-card title="Tender Page">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Category">
                <b-form-input
                  v-model="page.translation.mr.category"
                  placeholder="Category"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Sub Tender Section"
          ><b-form
            class="repeater-form"
            @submit.prevent="repeatTenderSection('mr')"
          >
            <b-row
              v-for="(tender, index) in page.translation.mr.tenderSection"
              :key="index"
              ref="tenderItemRow"
            >
              <b-col cols="10">
                <b-form-group label="Tender Type">
                  <b-form-input
                    v-model="page.translation.mr.tenderSection[index].tenderType"
                    placeholder="Section Title"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="2" md="3" class="mb-50">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeTenderSection('mr', index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Remove</span>
                </b-button>
              </b-col>
              <b-col cols="10">
                <b-form-group label="Description">
                  <b-row>
                    <b-col cols="12">
                      <quill-editor
                        v-model="page.translation.mr.tenderSection[index].description"
                        :options="snowOption"
                      />
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col>
                <b-card style="background: #f5f5f5" title="Tender Items">
                  <b-col cols="12">
                    <b-form
                      class="repeater-form"
                      @submit.prevent="repeatTender('mr', index)"
                    >
                      <b-row
                        v-for="(tender, index1) in page.translation.mr.tenderSection[index]
                          .items"
                        :key="index1"
                        ref="tenderItemRow"
                      >
                        <b-col cols="5">
                          <b-form-group label="Title">
                            <b-form-input
                              v-model="
                                page.translation.mr.tenderSection[index].items[index1].title
                              "
                              placeholder="Title"
                            />
                          </b-form-group>
                          <b-form-group label="PDF Link">
                            <b-form-input
                              v-model="
                                page.translation.mr.tenderSection[index].items[index1].pdfLink
                              "
                              placeholder="PDF Link"
                            />
                          </b-form-group>
                          <b-form-checkbox
                            checked="true"
                            v-model="
                              page.translation.mr.tenderSection[index].items[index1].hasNoDate
                            "
                            name="check-button"
                            class="mb-1 mt-1"
                            switch
                            inline
                          >
                            Disable Date
                          </b-form-checkbox>
                        </b-col>
                        <b-col
                          v-if="
                            !page.translation.mr.tenderSection[index].items[index1].hasNoDate
                          "
                          cols="5"
                        >
                          <b-form-group label="Last Date">
                            <b-calendar
                              v-model="
                                page.translation.mr.tenderSection[index].items[index1].lastDate
                              "
                              block
                              locale="en-US"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col lg="2" md="3" class="mb-50">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeTenderItem('mr', index, index1)"
                          >
                            <feather-icon icon="XIcon" class="mr-25" />
                            <span>Remove</span>
                          </b-button>
                        </b-col>
                        <b-col cols="12">
                          <b-form
                            class="repeater-form"
                            @submit.prevent="
                              repeatChildTender('mr', index, index1)
                            "
                          >
                            <b-row
                              v-for="(tender, index2) in page.translation.mr.tenderSection[
                                index
                              ].items[index1].childItems"
                              :key="index2"
                              ref="childTenderItemRow"
                            >
                              <b-col cols="12">
                                <b-card
                                  style="background: white"
                                  :title="'Tender series ' + (index2 + 1)"
                                >
                                  <b-row>
                                    <b-col cols="5">
                                      <b-form-group label="Title">
                                        <b-form-input
                                          v-model="
                                            page.translation.mr.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].title
                                          "
                                          placeholder="Section Title"
                                        />
                                      </b-form-group>
                                      <b-form-group label="PDF Link">
                                        <b-form-input
                                          v-model="
                                            page.translation.mr.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].pdfLink
                                          "
                                          placeholder="PDF Link"
                                        />
                                      </b-form-group>
                                      <b-form-checkbox
                                        checked="true"
                                        v-model="
                                          page.translation.mr.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        name="check-button"
                                        class="mb-1 mt-1"
                                        switch
                                        inline
                                      >
                                        Disable Date
                                      </b-form-checkbox>
                                    </b-col>

                                    <b-col cols="5">
                                      <b-form-group
                                        v-if="
                                          !page.translation.mr.tenderSection[index].items[
                                            index1
                                          ].childItems[index2].hasNoDate
                                        "
                                        label="Last Date"
                                      >
                                        <b-calendar
                                          v-model="
                                            page.translation.mr.tenderSection[index].items[
                                              index1
                                            ].childItems[index2].lastDate
                                          "
                                          block
                                          locale="en-US"
                                        />
                                      </b-form-group>
                                    </b-col>
                                    <b-col lg="2" md="3" class="mb-50">
                                      <b-button
                                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                        variant="outline-danger"
                                        class="mt-0 mt-md-2"
                                        @click="
                                          removeTenderChildItem(
                                            'mr',
                                            index,
                                            index1,
                                            index2
                                          )
                                        "
                                      >
                                        <feather-icon
                                          icon="XIcon"
                                          class="mr-25"
                                        />
                                        <span>Remove</span>
                                      </b-button>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col class="mt-1"
                                ><b-button
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  @click="
                                    repeatChildTender('mr', index, index1)
                                  "
                                >
                                  <feather-icon icon="PlusIcon" class="mr-25" />
                                  <span>{{
                                    page.translation.mr.tenderSection[index].items[index1]
                                      .childItems.length === 0
                                      ? "Add Series Tender Items"
                                      : "Add New Series Item"
                                  }}</span>
                                </b-button></b-col
                              >
                            </b-row>
                          </b-form>
                        </b-col>
                        <b-col cols="12">
                          <hr />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col class="mt-1"
                          ><b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="repeatTender('mr', index)"
                          >
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>{{
                              page.translation.mr.tenderSection[index].items.length === 0
                                ? "Add Tender Item"
                                : "Add New Tender Item"
                            }}</span>
                          </b-button></b-col
                        >
                      </b-row>
                    </b-form>
                  </b-col>
                </b-card>
              </b-col>

              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
            <b-row>
              <b-col class="mt-1"
                ><b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="repeatTenderSection('mr')"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>{{
                    page.translation.mr.tenderSection.length === 0
                      ? "Add Tender Section"
                      : "Add New Tender Section"
                  }}</span>
                </b-button></b-col
              >
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BCalendar,
  BForm,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    quillEditor,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    vSelect,
    BCalendar,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      tenders: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      page: {
        pageTitle: "",
        category: "",
        tenderSection: [],
        translation: {
          hi: {
            pageTitle: "",
            category: "",
            tenderSection: [],
          },
          mr: {
            pageTitle: "",
            category: "",
            tenderSection: [],
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getTenderItems() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/tenders/tenderItems/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log("tender res", response.data);
          this.tenders = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Tender Data");
        });
    },
    removeTenderChildItem(lang = undefined, index, index1, index2) {
      if (lang) {
        this.page.translations[lang].tenderSection[index].items[
          index1
        ].childItems.splice(index2, 1);
      } else {
        this.page.tenderSection[index].items[index1].childItems.splice(
          index2,
          1
        );
      }
    },
    disableDate(lang = undefined, index, index1) {
      console.log(this.page.tenderSection[index].items[index1].lastDate);
      if (lang) {
        this.page.translations[lang].tenderSection[index].items[
          index1
        ].lastDate = "";
      } else {
        this.page.tenderSection[index].items[index1].lastDate = " ";
      }
      console.log(
        "date",
        this.page.tenderSection[index].items[index1].lastDate
      );
    },
    removeTenderItem(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].tenderSection[index].items.splice(
          index1,
          1
        );
      } else {
        this.page.tenderSection[index].items.splice(index1, 1);
      }
    },
    removeTenderSection(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].tenderSection.splice(index, 1);
      } else {
        this.page.tenderSection.splice(index, 1);
      }
    },
    repeatChildTender(lang = undefined, index, index1) {
      if (lang) {
        this.page.translations[lang].tenderSection[index].items[
          index1
        ].childItems.push({
          title: "",
          pdfLink: "",
          lastDate: "",
        });
      } else {
        this.page.tenderSection[index].items[index1].childItems.push({
          title: "",
          pdfLink: "",
          lastDate: "",
        });
      }
    },
    repeatTender(lang = undefined, index) {
      if (lang) {
        this.page.translations[lang].tenderSection[index].items.push({
          title: "",
          pdfLink: "",
          lastDate: "",
          childItems: [],
        });
      } else {
        this.page.tenderSection[index].items.push({
          title: "",
          pdfLink: "",
          lastDate: "",
          childItems: [],
        });
      }
    },
    repeatTenderSection(lang = undefined) {
      if (lang) {
        this.page.translations[lang].tenderSection.push({
          tenderType: "",
          description: "",
          items: [],
        });
      } else {
        this.page.tenderSection.push({
          tenderType: "",
          description: "",
          items: [],
        });
      }
    },
    saveDraft() {
      localStorage.setItem("tender-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("tender-page-data");
      this.page = JSON.parse(page);
      console.log(this.page)
    },
    removeDraft() {
      localStorage.removeItem("tender-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/tenders/tender-page/get`)
        .then((response) => {
          this.page = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/tenders/tenderItems/create`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
         .then((response) => {
              console.log('update')
              this.makeToast(
                "success",
                "Success",
                `${response.data.pageTitle} has been add successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while adding the tender!"
              );
              console.log(error.response);
            });
    },
  },
  mounted() {
    if (localStorage.getItem("tender-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getTenderItems();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>